import React, { ForwardedRef  } from 'react';
import './generatePdf.scss'
import { Row, Col, Divider } from 'antd';
import logo from "../../../assets/images/logo-with-hummingbird.svg"
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const GeneratePDF = React.forwardRef<HTMLDivElement>((props, ref: ForwardedRef<HTMLDivElement>) => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const ciphertext = urlParams.get('data') ?? '';
    
    const ciphertextWA = CryptoJS.enc.Hex.parse(ciphertext);
    
    const keyWA = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PDF_DECRYPT_KEY!);
    const ivWA = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PDF_DECRYPT_IV!);
    
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: ciphertextWA,
      key: keyWA,
      iv: ivWA,
    });
    
    const decrypted = CryptoJS.AES.decrypt(cipherParams, keyWA, { iv: ivWA });
    
    const username = decrypted.toString(CryptoJS.enc.Utf8);
    return (
    <div ref={ref}>
        <div className='pdf-container'>
            <div className='logo-container'>
                <div><img src={logo} /></div>
                </div>
            <Row className='container' gutter={[16,16]}>
                <Col xs={4} md={2} className='left-layout'>
                    <div className='header'>الموضوع</div>
                    <div>انذار بسرعة سداد مبلغ المحدد المقدار والمعلوم والثابت استلامه بتوقيع
الالكتروني لمنذر اليه</div>
                </Col>
                <Col xs={2} md={1}><Divider type="vertical" /></Col>
                <Col xs={18}  md={21} className='right-layout'>
                <div className='section-one'>
                    <div className='header'>ادارة الشئون القانونية</div>
                    <div className='line'>
                    بناء علي طلب شركة رايز إيجيبت للخدمات المالية Rise Egypt وملحها المختار إداره الشئون القانونية الكائن مقرها بمقر الشركة
                    </div>
                    <div  className='line'>
                    انتقل مندوبنا بادارة التحصيل للشركة وأعلن:-
                    </div>
                    <div  className='line'>{username}</div>
                </div>
                <div className='section-two'>
                    <div className='header'>وأنذرته بالاتي</div>
                    <div className='paragraph-1'>
                    تقوم شركة رايز ايجيبت بتقديم خدمات لعملائها والذي بموجبها يستطيع العميل شراء المنتج المعروض بكافة المتاجر المتعاقدة مع ابلكيشن رايز ايجيبت وذلك عن طريق تمويل الدفع بالتقسيط (خدمة السداد المؤجل ) وهذا بعد ان يقوم العميل بتصوير نفسه سلفي وتصوير بطاقة الرقم القومي علي الوجهيين واخيراً يقوم العميل بالتوقيع علي العقد
الالكتروني والموافقة علي الشروط والاحكام والتي من خلال تلك الشروط .
                    <div className='paragraph-1'>
                    ولما كان هذا الدين ثابت عن طريق الشراء باستخدام رمز الاستجابة السريع QR وحال الأداء ومعين المقدار فان الطالب قد طالب المنذر إليه وديا بسداد هذا المبلغ إلا انه لم يحرك ساكنا الأمر الذي حدا بالطالب إلي توجيه هذا الإنذار للمنذر إليه مكلفا إياه ومنبها عليه بضرورة سداد هذا المبلغ وذلك في موعد لا يتجاوز عشر ايام من تاريخ تسلمه لهذا الإنذار وإلا فان الطالب سيضطر أسفا إلي اتخاذ الإجراءات القانونية ضدكم ، الامر الذي سوف يعرضك ذلك للمسئوليةالجنائية والحبس  والغرامة والتعويضات المدنية وذلك
                    لانك اصبحت خائنا و بددآ للامانة .
                    </div>
 
                    </div>
                    <div className='paragraph-2'>
                        حيث نصت المادة رقم (341) من قانون العقوبات علي أن " كل من اختلس أو استعمل أو بدد مبالغ أو أمتعة
                        أو بضائع أو نقودا أو تذاكر أو كتابات أخرى مشتملة على تمسك أو مخالصة أو غير ذلك إضرارا بمالكيها
                        أو أصحابها أو واضعي اليد عليها وكانت الأشياء المذكورة لم تسلم له إلا على وجه الوديعة أو الإجارة
                        أو على سبيل عارية الاستعمال أو الرهن أو كانت سلمت له بصفة كونه وكيلا بأجرة أو مجاناً بقصد عرضها
                        للبيع أو بيعها أو استعمالها في أمر معين لمنفعة المالك لها أو غيره يحكم عليه بالحبس ويجوز أن يزاد
                        عليه غرامة لا تتجاوز مائة جنيه مصري.
                    </div>
                    <div className='paragraph-1'>
                    يرجي الاتصال علي رقم خدمه العملاء الخاص بشركه رايز ايجيبت في أسرع وقت و هو 0233335526 قبل اتخاذ الاجراءات القانونية اللازمة
                    </div>
                </div>

                    <div className='section-three'>
                        <div className='header'>لذلـــــــــك </div>
                    <div className='line'>يتوجه المنذر الي المنذر إليه منبها إياه بسرعة سداد كافه المبالغ المستحقة والا سوف يضطر
المنذر اتخاذ كافة الاجراءات القانوية تجاه ذلك وهذا الانذار يعتبر اخر الطرق الودية واول الطرق القانونية</div>
                    <div className='line'>.ولاجل العلم</div>
                    <div className='line'>،،،،،،،،</div>
                </div>
                </Col>
            </Row>
        </div>
    </div>
)});

export default GeneratePDF;