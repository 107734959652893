export const openAppStore = () => {
    window.open('https://apps.apple.com/us/app/rise-egypt/id6446939846', '_blank');
};

export const oneLink = () => window.open('https://riseco.onelink.me/b7r1/yg81dkrf', '_blank')

export const openGooglePlaystore = () => {
    window.open('https://play.google.com/store/search?q=rise%20egypt&c=apps&hl=en-IN', '_blank');
}

export const openInstagram = () => {
    window.open('https://www.instagram.com/rise.egy?igsh=czRydGZnY3pscXFm', '_blank');
}
// TODO: NEED TO BE UPDATED
export const openLinkedIn = () => {
    window.open('https://www.linkedin.com/company/risecoegypt/', '_blank');
}

export const openFacebook = () => {
    window.open('https://www.facebook.com/profile.php?id=61553288847012&mibextid=ZbWKwL', '_blank');
}

