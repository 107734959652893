import React from 'react';
import AppRoutes from './routes';
import 'antd/dist/antd.css';
import "./styles/_main.scss";
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './shared/components/ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <AppRoutes />
    </Router>
  );
}

export default App;
