import i18next from "i18next";
import { useEffect, useState } from "react";
import { getCookies } from "../../shared/utils/cookiesHelper";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import varnaLogo from "./../../assets/images/partners/varna.png"
import concreteLogo from "./../../assets/images/partners/concrete.png"
import cortefielLogo from "./../../assets/images/partners/cortefiel.png"
import osamasalamaLogo from "./../../assets/images/partners/osamasalama.png"
import sistersandcoLogo from "./../../assets/images/partners/sistersandco.png"
import elshazlyLogo from "./../../assets/images/partners/elshazly.png"
import dlingerieLogo from "./../../assets/images/partners/dlingerie.png"
import guruLogo from "./../../assets/images/partners/guru.png"
import springfieldLogo from "./../../assets/images/partners/springfield.png"
import tomaLogo from "./../../assets/images/partners/toma.png"
import tomatoLogo from "./../../assets/images/partners/tomato.png"
import candcoLogo from "./../../assets/images/partners/candco.png"
import beverlyhillspoloclubLogo from "./../../assets/images/partners/beverlyhillspoloclub.png"
import birkenstockLogo from "./../../assets/images/partners/birkenstock.png"
import barakaLogo from "./../../assets/images/partners/baraka.png"
import newbalanceLogo from "./../../assets/images/partners/newbalance.png"
import nikeLogo from "./../../assets/images/partners/nike.png"
import peopleLogo from "./../../assets/images/partners/people.png"
import React from "react";
import Navbar from "../../shared/components/NavBar";
import Home from "./Home";
import Numbers from "./Numbers";
import Partners from "./Partners";
import HowItWorks from "./HowItWorks";
import AboutUs from "./AboutUs";
import FAQ from "./FAQ";
import FooterComponent from "./Footer";
import MobileNavbar from "../../shared/components/MobileNavBar";

const LandingPage = () => {
    const language = getCookies().i18next || i18next.language
    const [isErrored, setIsErrored] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation();

    const images = [
        nikeLogo,
        newbalanceLogo,
        barakaLogo,
        beverlyhillspoloclubLogo,
        birkenstockLogo,
        candcoLogo,
        concreteLogo,
        cortefielLogo,
        varnaLogo,
        dlingerieLogo,
        elshazlyLogo,
        guruLogo,
        peopleLogo,
        osamasalamaLogo,
        springfieldLogo,
        sistersandcoLogo,
        tomaLogo,
        tomatoLogo
    ]

    const midIndex = Math.floor(images.length / 2);
    const firstPart = images.slice(0, midIndex);
    const secondPart = images.slice(midIndex);

    const isMobile = window.innerWidth < 480

    const renderPartnerContainerGroup = (imagesData: string[]) => (
        <div className="partner-container">
            {Array(2).fill(1).map(()=>(
                <div className={`partner-container__group ${language === "ar" && 'arabic'}`}>
                    {imagesData?.map((image) => <img src={image} alt="" />)}
                </div>
            ))}
        </div>
    )

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 250)
    }, [language])

    const sections = [
        { id: 'home', label: 'Home' },
        { id: 'numbers', label: 'Numbers' },
        { id: 'partners', label: 'Partners' },
        { id: 'how-it-works', label: 'How it works' },
        { id: 'about-us', label: 'About us' },
        { id: 'faq', label: 'FAQ' },
      ];

    return (
        <div className="landing-page">
            {
                loading ?
                    <Spin /> :
                    <div className={`landing-page`}>
                        {!isMobile ? <Navbar sections={sections} footer={false} /> : <MobileNavbar sections={sections} />}
                        <Home />
                        <Numbers />
                        <Partners />
                        <HowItWorks />
                        <AboutUs />
                        <FAQ />
                        <FooterComponent />
                    </div>
            }
        </div>
    )
}

export default LandingPage;
