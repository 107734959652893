import React, { FC, useEffect, useRef } from 'react'
import GeneratePDF from './GeneratePDF'
import "./legaldocument.scss";

interface LegalDocumentProps { }

const LegalDocument: FC<LegalDocumentProps> = (props) => {
    const componentRef = useRef<HTMLDivElement>(null);

    return (
        <div className='legaldocument'>
            <GeneratePDF ref={componentRef}/>
        </div>
    )
}

export default LegalDocument