import React, { useEffect } from "react";
import {
  Navigate,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "../views/Home";
import { AppRoutes } from "./routeConstants/appRoutes";
import { PrivacyPolicy } from "../views/PrivacyPolicy";
import { TermsAndConditions } from "../views/TermsAndConditions";
import i18next from "i18next";
import Ambassadors from "../views/Ambassadors";
import { getCookies } from "../shared/utils/cookiesHelper";
import LandingPage from "../views/LandingPage";
import LegalDocument from "../views/LegalDocument";

export const appHistory = createBrowserHistory();

const AppRouter = () => {

  const language = getCookies().i18next || i18next.language

  const [searchParams] = useSearchParams()

  useEffect(() => {
    i18next.changeLanguage(searchParams.get("lang") as string)
  }, [searchParams, i18next.language])
  
  const routes = useRoutes([
    {
      path: AppRoutes.ROOT,
      element: <LandingPage />,
    },
    {
      path: AppRoutes.PRIVACY_POLICY,
      element: <PrivacyPolicy />,
    },
    {
      path: AppRoutes.TERMS_AND_CONDITIONS,
      element: <TermsAndConditions />,
    },
    {
      path: AppRoutes.AMBASSADORS,
      element: <Ambassadors />,
    },
    {
      path: AppRoutes.LEGAL,
      element: <LegalDocument />
    },
    {
      path: "*",
      element: <Navigate to={AppRoutes.ROOT} replace/> ,
    }
  ]);

  return (
    <div className={`app-router`}>{routes}</div>
  );
};

export default AppRouter;
